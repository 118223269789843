@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  border: 0;
  padding: 0;
  margin: 0;
}

.box {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;

  h1 {
    margin-bottom: 50px;
    text-align: center;
    width: 70%;
  }
}
